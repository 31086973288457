import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import { PageSeo } from "../components/PageSeo"

const PressReleasesTemplate = ({ data }) => {
  const { markdownRemark: markup } = data
  const { banner } = markup.frontmatter
  const pressArr = data.allMarkdownRemark.edges
  const whiteArr = data.whitepapers.edges
  const caseArr = data.casestudies.edges

  var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      ;(rv[x.node.frontmatter[key]] = rv[x.node.frontmatter[key]] || []).push(x)
      return rv
    }, {})
  }
  const groupData = groupBy(pressArr, "year")

  return (
    <Layout>
      <Banner data={banner} />

      <section className="container">
        <div className="row PressReleasesTemplate my-5">
          <div className="col-12 col-lg-8 py-3 press-releases-content">
            {Object.keys(groupData)
              .reverse()
              .map((key, index) => {
                return (
                  <div key={index}>
                    <h3 className="text-muted">{key}</h3>
                    <div className="row pb-3">
                      {groupData[key].length &&
                        groupData[key].map(({ node }, index) => (
                          <PressContentTemplate
                            key={`whitepapersArr_${index}`}
                            item={node.frontmatter}
                            url={node.fields.slug}
                          />
                        ))}
                    </div>
                  </div>
                )
              })}
          </div>

          <div className="col-12 col-lg-3 press-releases-right flex-grow-1">
            <div className="row mb-2 mb-lg-3">
              <h4 className="section_heading pb-3">Whitepapers</h4>

              {whiteArr.map(({ node }, index) => (
                <WhitePapersContent
                  key={`whiteArr_${index}`}
                  item={node.frontmatter}
                  index={index}
                  url={node.fields.slug}
                />
              ))}
            </div>

            <div className="row">
              <h4 className="section_heading pb-3">Case Studies</h4>

              {caseArr.map(({ node }, index) => (
                <CaseStudiesContent
                  key={`caseArr_${index}`}
                  item={node.frontmatter}
                  index={index}
                  url={node.fields.slug}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

const PressContentTemplate = ({ item, url }) => {
  return (
    <Link to={url} className="text-decoration-none">
      <div className="card mb-3 bg-light text-black">
        <div className="card-body">
          <h4 className="card-title text-primary">{item.name}</h4>
          <p className="mb-1">{item.description}</p>
          <div className="text-muted">
            <em>{item.venue}</em>
          </div>
        </div>
      </div>
    </Link>
  )
}

const WhitePapersContent = ({ item, index, url }) => {
  return (
    <Link
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="text-decoration-none"
    >
      <div className="card mb-3 border-0 border-bottom rounded-0">
        <div className="card-body p-0">
          <strong className="fs-6 text-primary pe-3">
            {index < 9 ? "0" : ""}
            {(item.index = index + 1)}
          </strong>
          <h4 className="card-title sm text-black">{item.name}</h4>
          <div className="text-muted">
            <em>{item.year}</em>
          </div>
        </div>
      </div>
    </Link>
  )
}

const CaseStudiesContent = ({ item, index, url }) => {
  return (
    <Link to={url} className="text-decoration-none">
      <div className="card mb-3 border-0 border-bottom rounded-0">
        <div className="card-body p-0">
          <strong className="fs-6 text-primary pe-3">
            {index < 9 ? "0" : ""}
            {(item.index = index + 1)}
          </strong>
          <h4 className="card-title sm text-black">{item.name}</h4>
        </div>
      </div>
    </Link>
  )
}

export const PressQuery = graphql`
  query PressQuery($id: String) {
    allMarkdownRemark(
      sort: { fields: frontmatter___sequence, order: ASC }
      filter: { frontmatter: { templateKey: { eq: "press-releases-slug" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
            description
            year
            venue
          }
        }
      }
    }

    whitepapers: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___sequence] }
      filter: { frontmatter: { homeSection: { eq: "whitepapers" } } }
    ) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            name
            description
            sequence
            year
            link
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }

    casestudies: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___sequence] }
      filter: { frontmatter: { templateKey: { eq: "case-studies-slug" } } }
    ) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            name
            description
            sequence
            link
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }

    markdownRemark(id: { eq: $id }) {
      frontmatter {
        name
        venue
        banner {
          heading
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
export default PressReleasesTemplate
export const Head = () => (
  <PageSeo
    title="CREDENCE ANALYTICS | Press Releases | INVESTMENT MANAGEMENT SOFTWARE SOLUTION PROVIDER"
    description="BDO, TOAP Convention 2018, media, press releases, whitepapers, news, case studies, events"
    keywords="BDO, IBS Intelligence, Gurmeet Singh Tuteja, Mabledon Capital Limited, Hindalco, Edelweiss General Insurance, DHFL General Insurance, Acko general insurance, African Alliance Investment Bank (PIVOT Limited), Go Digit, Hannover Re-Insurance, TOAP Convention 2018, GO LIVE, RESEARCH PAPER, ANNOUCEMENT, NEW WIN, RANKED #1"
  />
)
